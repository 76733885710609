import { FilterableTable } from "@atlas-ui/components";
import { Document, DocumentKeyValuePair } from "@atlas-ui/types";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";

export const createSortOptions = (
  field: string,
  labelAscending: string,
  labelDescending: string,
  reverse?: boolean
) => {
  const options = [
    {
      label: (
        <FilterableTable.TableSortLabel
          label={labelAscending}
          icon={<ArrowUpIcon />}
        />
      ),
      value: `${field}:desc`,
    },
    {
      label: (
        <FilterableTable.TableSortLabel
          label={labelDescending}
          icon={<ArrowDownIcon />}
        />
      ),
      value: `${field}:asc`,
    },
  ];

  if (reverse) {
    options.reverse();
  }

  return options;
};

export const getKvpValueByCodeAccessor =
  (code: number, key: keyof DocumentKeyValuePair = "value") =>
  (document: Document) => {
    return document.kvps?.find((kvp) => kvp.keyValuePair?.code === code)?.[key];
  };
