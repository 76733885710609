import { createContext } from "react";

import {
  Document,
  DocumentParty,
  DocumentTab,
  DocumentTag,
  DocumentType,
} from "@atlas-ui/types";

import { InvalidFile } from "@/lib/common/helpers/files-helper";

export const DEFAULT_SORTING_TYPE = "createdAt:desc";

export enum SpecialFilters {
  nonStandardNDA = "nonStandardNDA",
  noMarketingRights = "noMarketingRights",
  oneSidedIndemnity = "oneSidedIndemnity",
  nonStandardLiability = "nonStandardLiability",
  autoRenewal = "autoRenewal",
  noAutoRenewal = "noAutoRenewal",
}

export interface MyDocumentsContextType {
  currentPage: number;
  documentTypes: DocumentType[];
  documents: Document[];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  parties: DocumentParty[];
  searchValue: string;
  selectedDocumentType?: string | null;
  selectedParties: string[];
  setCurrentPage: (value: number) => void;
  setSearchValue: (value: string) => void;
  setSelectedDocumentType: (value: string) => void;
  setSelectedParties: (value: string[]) => void;
  setSortingOption: (value: string) => void;
  sortingOption: string;
  totalDocumentCount: number;
  selectedTab: DocumentTab;
  specialFilters: {
    [SpecialFilters.nonStandardNDA]?: boolean;
    [SpecialFilters.noMarketingRights]?: boolean;
    [SpecialFilters.oneSidedIndemnity]?: boolean;
    [SpecialFilters.nonStandardLiability]?: boolean;
    [SpecialFilters.autoRenewal]?: boolean;
    [SpecialFilters.noAutoRenewal]?: boolean;
  };
  setSpecialFilters: (filter: SpecialFilters, value: boolean) => void;
  setSelectedTab: (value: DocumentTab) => void;
  tags: DocumentTag[];
  setSelectedTags: (value: string[]) => void;
  selectedTags: string[];
  isUploadCardOpen: boolean;
  isFiltersApplied: boolean;
  setIsUploadCardOpen: (value: boolean) => void;
  documentsInProgress: Document[];
  loadNextPage: () => void;
  uploadDocuments: (
    files: File[],
    invalidFiles: InvalidFile[]
  ) => Promise<void>;
  setUploadOpen: (value: boolean) => void;
  onUploadClick: () => void;
  isUploadingFiles: boolean;
  inProgressDocuments: Document[];
  validInProgressDocuments: Document[];
  canUploadMoreDocuments: boolean;
  clearFilters: () => void;
  tagsMode: "and" | "or";
  setTagsMode: (value: "and" | "or") => void;
}

export const MyDocumentsContext = createContext<MyDocumentsContextType>({
  documents: [],
  validInProgressDocuments: [],
  isLoading: false,
  isFetchingNextPage: false,
  searchValue: "",
  totalDocumentCount: 0,
  currentPage: 1,
  sortingOption: DEFAULT_SORTING_TYPE,
  setSortingOption: () => {},
  setCurrentPage: () => {},
  setSearchValue: () => {},
  documentTypes: [],
  setSelectedDocumentType: () => {},
  selectedDocumentType: "",
  setSelectedParties: () => {},
  selectedParties: [],
  parties: [],
  selectedTab: DocumentTab.ACTIVE,
  setSelectedTab: () => {},
  setSelectedTags: () => {},
  selectedTags: [],
  tags: [],
  setIsUploadCardOpen: () => {},
  isUploadCardOpen: false,
  isFiltersApplied: false,
  documentsInProgress: [],
  specialFilters: {},
  setSpecialFilters: () => {},
  isUploadingFiles: false,
  onUploadClick() {},
  setUploadOpen(value: boolean) {},
  loadNextPage: () => {},
  uploadDocuments: async () => {},
  inProgressDocuments: [],
  canUploadMoreDocuments: false,
  clearFilters: () => {},
  tagsMode: "and",
  setTagsMode: () => {},
});
