import { FC, useContext } from "react";

import { DocumentTab } from "@atlas-ui/types";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

import { MyDocumentsContext } from "../../../lib";

const tabStatuses = {
  [DocumentTab.ACTIVE]: {
    iconColor: "primary",
    text: "Active",
  },
  [DocumentTab.EXPIRED]: {
    iconColor: "destructive",
    text: "Expired",
  },
  [DocumentTab.ALL]: {
    iconColor: "foreground",
    text: "All",
  },
};

export const MyDocumentsNameHeader: FC = () => {
  const { selectedTab } = useContext(MyDocumentsContext);

  const iconColor =
    tabStatuses[selectedTab as keyof typeof tabStatuses].iconColor;
  const text = tabStatuses[selectedTab as keyof typeof tabStatuses].text;

  return (
    <div className="flex gap-2 min-w-[150px]">
      <DocumentDuplicateIcon
        className={`text-${iconColor} w-[18px] h-[18px]`}
      />
      <span>{text} Documents</span>
    </div>
  );
};
