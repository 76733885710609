import { RefObject, useCallback } from "react";
import { InfiniteData, useQueryClient } from "react-query";

import { getDocumentById } from "@atlas-ui/services";
import {
  Document,
  DocumentStatus,
  DocumentTab,
  PaginatedResponse,
} from "@atlas-ui/types";
import { Entity, EventData, EventType, useEvent } from "@atlas-ui/utils";

import { IN_PROGRESS_QUERY_KEY } from "@/app/documents/[id]/lib/constants";

import { deleteDocumentInCache, upsertDocumentInCache } from "../lib/utils";

interface UseMyDocumentsWebsocketEvents {
  documentTypeId?: string | null;
  currentPage: number;
  currentQueryKey: string[];
  postedDocuments: RefObject<string[]>;
  onlyHandleLocalEvents?: boolean;
  currentTab: DocumentTab;
}

export const useMyDocumentsWebsocketEvents = ({
  documentTypeId,
  currentQueryKey,
  currentPage,
  postedDocuments,
  onlyHandleLocalEvents = true,
  currentTab,
}: UseMyDocumentsWebsocketEvents) => {
  const queryClient = useQueryClient();

  const handleWebsocketEvent = useCallback(
    async (type: EventType, data: EventData) => {
      switch (type) {
        case EventType.DELETE:
          deleteDocumentInCache(queryClient, currentQueryKey, data);
          break;
        case EventType.UPDATE: {
          let receivedDocument: Document = data as unknown as Document;
          const loadedDocument = await getDocumentById(data.id);

          queryClient.setQueryData(
            IN_PROGRESS_QUERY_KEY,
            (oldData?: Document[]) => {
              if (!oldData) return [];

              return oldData.map((doc) => {
                if (doc.id === receivedDocument.id) {
                  return {
                    ...doc,
                    ...loadedDocument,
                  };
                }

                return doc;
              });
            }
          );

          const cachedData =
            queryClient.getQueryData<InfiniteData<PaginatedResponse<Document>>>(
              currentQueryKey
            );

          const isDocumentInCache = cachedData?.pages?.some((page) =>
            page.result.some((doc) => doc.id === receivedDocument.id)
          );

          // Only handle update events for documents has been posted by this browser
          // This can be disabled by passing `onlyHandleLocalEvents` as false
          if (
            onlyHandleLocalEvents &&
            !postedDocuments.current?.includes(receivedDocument.id) &&
            !isDocumentInCache
          ) {
            console.warn(
              "Ignoring update event for document",
              receivedDocument
            );
            return;
          }

          // Only include item in the list if it has finished its processing
          const isDocumentFinished = [
            DocumentStatus.extraKvpCompleted,
            DocumentStatus.completed,
          ].includes(data.status as DocumentStatus);

          if (!isDocumentFinished) {
            return;
          }

          receivedDocument = {
            ...loadedDocument,
            kvps:
              loadedDocument.kvps?.filter(
                (kvp) => kvp.isFavorite || kvp.keyValuePair?.isDefault
              ) ?? [],
          };

          // Upsert if user is on the corresponding tab
          upsertDocumentInCache({
            queryClient: queryClient,
            currentQueryKey: currentQueryKey,
            receivedDocument: receivedDocument,
            currentPage: currentPage,
          });

          await queryClient.invalidateQueries({
            predicate: (query) => {
              if (!Array.isArray(query.queryKey)) return false;
              return ["documents", "parties"].includes(query.queryKey[0]);
            },
          });
          break;
        }
        case EventType.CREATE:
          await queryClient.invalidateQueries({
            predicate: (query) => {
              if (!Array.isArray(query.queryKey)) return false;
              return ["inProgressDocuments", "documents"].includes(
                query.queryKey[0]
              );
            },
          });
          break;
        default:
          break;
      }
    },
    [queryClient, currentQueryKey, postedDocuments, documentTypeId]
  );

  useEvent({
    entity: Entity.DOCUMENT,
    events: [EventType.CREATE, EventType.UPDATE, EventType.DELETE],
    handler: handleWebsocketEvent,
  });
};
