import { FormSchema } from "@atlas-ui/components";

import { EasingDefinition } from "framer-motion";
import * as Yup from "yup";

export enum KeyValuePairOperation {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
}

export interface KeyValuePairOperationOptions {
  isFavorite?: boolean;
  isDeleting?: boolean;
  trackUpdateEvent?: boolean;
}

export const keyValuePairValidationInfo = Yup.object({
  name: Yup.string().required("The label cannot be empty"),
  value: Yup.string().required("The value cannot be empty"),
  section: Yup.string().required("The section cannot be empty"),
});

export const getContractPriceValidationSchema = (schema: FormSchema) => {
  const baseSchema = {
    currency: Yup.string().required("Currency is required"),
    amount: Yup.string()
      .required("Amount is required")
      .matches(/^\d+(\.\d+)?$/, "Amount must be a valid number"),
    // Payment Structure validation
    installmentAmount: Yup.string()
      .matches(/^\d+(\.\d+)?$/, "Installment Amount must be a valid number")
      .nullable(),
    installmentCurrency: Yup.string().nullable(),
    paymentPeriod: Yup.string().nullable(),
    dueDate: Yup.string().nullable(),
    // Late Payment Penalty validation
    penaltyRate: Yup.string()
      .matches(/^\d+(\.\d+)?$/, "Late Payment Rate must be a valid number")
      .nullable(),
    penaltyPeriod: Yup.string().nullable(),
    calculationBasis: Yup.string().nullable(),
  };

  // Add validation for section fields if they exist in the schema
  const sectionFields = Object.entries(schema)
    .filter(([key]) => key.startsWith("section_"))
    .reduce((acc, [key]) => {
      acc[key] = Yup.string().required("Section content is required");
      return acc;
    }, {} as { [key: string]: Yup.StringSchema });

  return Yup.object().shape({
    ...baseSchema,
    ...sectionFields,
  });
};

export const IN_PROGRESS_QUERY_KEY = ["inProgressDocuments"];

export const collapseDefaultProps = {
  ease: "easeInOut" as EasingDefinition,
  duration: 0.4,
};
