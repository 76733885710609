import { useQuery } from "react-query";

import {
  getDocumentTypesWithCount,
  GetDocumentTypesWithCountParams,
} from "@atlas-ui/services";
import { DocumentTypeWithCount } from "@atlas-ui/types";

interface UseDocumentTypesWithCountProps<T = DocumentTypeWithCount[]> {
  transform?: (data: DocumentTypeWithCount[]) => T;
  onSuccess?: (data: DocumentTypeWithCount[]) => void;
  enabled?: boolean;
  params?: GetDocumentTypesWithCountParams;
}

export const useDocumentTypesWithCount = <T = DocumentTypeWithCount[]>(
  props?: UseDocumentTypesWithCountProps<T>
): {
  documentTypesWithCount: T;
  isLoadingDocumentTypesWithCount: boolean;
} => {
  const queryKey = [
    "document-types-count",
    props?.params?.tags?.join(",") ?? "",
    props?.params?.parties?.join(",") ?? "",
    props?.params?.searchValue ?? "",
    props?.params?.tab ?? "",
    Object.entries(props?.params?.specialFilters ?? {})
      .map(([key, value]) => `${key}:${value}`)
      .join(","),
  ];

  const { isLoading, data } = useQuery<DocumentTypeWithCount[]>({
    enabled: !!props?.enabled,
    queryKey: queryKey,
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryFn: () => {
      return getDocumentTypesWithCount({
        tags: props?.params?.tags,
        parties: props?.params?.parties,
        searchValue: props?.params?.searchValue,
        tab: props?.params?.tab,
        specialFilters: props?.params?.specialFilters,
      });
    },
    onSuccess: (data) => {
      props?.onSuccess?.(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const result = props?.transform ? props.transform(data ?? []) : data;

  return {
    documentTypesWithCount: (result ?? []) as T,
    isLoadingDocumentTypesWithCount: isLoading,
  };
};
