import { FileWithExtension } from "@atlas-ui/services";

import { createEvent, EventAttributes } from "ics";
import { uniq } from "lodash";

export type SupportedMimeType = keyof typeof FileFormats;

export enum FileFormats {
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = "docx",
  "application/pdf" = "pdf",
  "image/png" = "png",
  "image/jpeg" = "jpeg",
  "application/msword" = "doc",
}

export const validMimeTypes: SupportedMimeType[] = [
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
];

export const addExtensionToFile = (file: File): FileWithExtension => {
  const ext = FileFormats[file.type as SupportedMimeType];

  return { file, ext };
};

export interface UploadError {
  title: string;
  message: string;
}

export const getUnsupportedFiles = (
  files: File[],
  accepts: string[]
): File[] => {
  if (!accepts || accepts.length === 0) return [];
  return files.filter((file) => !accepts?.includes(file.type));
};

export const getSizeExceededFiles = (
  files: File[],
  maxFileLength?: number
): File[] => {
  if (maxFileLength === undefined) return [];

  return files.filter((file) => {
    return file.size > (maxFileLength ?? 0);
  });
};

export const getValidFiles = (
  files: File[],
  invalidFiles: InvalidFile[]
): File[] => {
  const invalidFilePrimitives = invalidFiles.map(({ file }) => file);

  return files.filter((file) => !invalidFilePrimitives.includes(file));
};

export enum InvalidFileReason {
  UNSUPPORTED = "unsupported",
  SIZE_EXCEEDED = "size_exceeded",
}

export interface InvalidFile {
  file: File;
  reason: InvalidFileReason;
}

export const getMaxFilesMessage = (maxFiles: number) => {
  if (!maxFiles) {
    return;
  }

  if (maxFiles === 1) {
    return null;
  }

  return `Max ${maxFiles} files`;
};

export const toMB = (bytes?: number) => {
  if (bytes === 0 || bytes === undefined) return "0";
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(bytes / (1024 * 1024));
};

export const maxFileLengthMessage = (maxFileLength?: number) => {
  if (!maxFileLength) {
    return;
  }
  return `Max file size ${toMB(maxFileLength)}Mb`;
};

export const acceptsMessage = (
  formats?: SupportedMimeType[]
): string | undefined => {
  if (!formats || formats.length === 0) {
    return;
  }

  const uniqueFormats = uniq(
    formats.map((type) => FileFormats[type].toUpperCase())
  );

  if (uniqueFormats.length === 1) {
    return `${uniqueFormats[0]} Only`;
  }

  if (uniqueFormats.length === 2) {
    return `${uniqueFormats[0]} or ${uniqueFormats[1]}`;
  }

  const lastFormat = uniqueFormats.pop();
  return `${uniqueFormats.join(", ")}, and ${lastFormat}`;
};

export const generateIcsFile = (
  event: EventAttributes
): Promise<{ blob: Blob; url: string }> => {
  return new Promise((resolve, reject) => {
    createEvent(event, (error, value) => {
      if (error) {
        reject(error);
      }

      const blob = new Blob([value], { type: "text/calendar" });
      const url = URL.createObjectURL(blob);
      resolve({ blob, url });
    });
  });
};
