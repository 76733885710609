const getKey = (key: string): string => {
  return `atlas-ui:${key}`;
};

const get = <T>(key: string, defaultValue?: T): T | undefined => {
  try {
    const item = window.localStorage.getItem(getKey(key));
    if (item === null) return defaultValue;
    return JSON.parse(item);
  } catch {
    return defaultValue;
  }
};

const set = <T>(key: string, value: T): void => {
  try {
    if (value === undefined) {
      remove(key);
      return;
    }
    window.localStorage.setItem(getKey(key), JSON.stringify(value));
  } catch (error) {
    console.error("Error setting localStorage item:", error);
  }
};

const remove = (key: string): void => {
  window.localStorage.removeItem(getKey(key));
};

const clear = (): void => {
  window.localStorage.clear();
};

const setWithQuotaHandling = <T>(
  key: string,
  value: T,
  preserveKeys: string[] = []
): void => {
  try {
    set(key, value);
  } catch (error) {
    if (
      error instanceof DOMException &&
      (error.name === "QuotaExceededError" ||
        error.name === "NS_ERROR_DOM_QUOTA_REACHED")
    ) {
      // Clear items except preserved keys
      const allKeys = Object.keys(localStorage);
      allKeys.forEach((storageKey) => {
        if (
          !preserveKeys.some((preserveKey) => storageKey.includes(preserveKey))
        ) {
          window.localStorage.removeItem(storageKey);
        }
      });

      // Try storing again
      try {
        set(key, value);
      } catch (e) {
        console.error("Still unable to store item after clearing storage:", e);
      }
    } else {
      throw error;
    }
  }
};

export const localStorage = {
  get,
  set,
  remove,
  clear,
  setWithQuotaHandling,
};
