import React, { FC, HTMLAttributes } from "react";

import { Separator, TruncatedBadge } from "@atlas-ui/components";
import { Document } from "@atlas-ui/types";
import { TagIcon } from "@heroicons/react/24/outline";

interface NameCellTagsProps extends HTMLAttributes<HTMLDivElement> {
  document: Document;
  onTagClicked: (tag: string) => void;
}

export const NameCellTags: FC<NameCellTagsProps> = ({
  document,
  onTagClicked,
  ...rest
}) => (
  <div className="flex items-start" {...rest}>
    <div className="flex gap-1 items-center mt-0.5">
      <TagIcon className="w-[14px] h-[14px] text-muted-foreground" />
      <span className="text-[10px] text-muted-foreground">Tags</span>
    </div>
    <Separator orientation="vertical" className="mx-2 my-0 h-4" />
    <div className="flex gap-1 items-center max-w-[500px] flex-wrap">
      {document.tags.map((tag) => (
        <TruncatedBadge
          variant="inactive"
          className="text-[10px] text-muted-foreground font-normal cursor-pointer"
          onClick={() => onTagClicked(tag.name)}
          key={`${tag.name}-${document.id}`}
        >
          {tag.name}
        </TruncatedBadge>
      ))}
    </div>
  </div>
);
